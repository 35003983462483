#loading, #maploader {
  position: fixed;
  z-index: 9999999999;
  background: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  color: #fff;
  transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
  -webkit-ransition: opacity 0.5s ease-out;
  -ms-transition: opacity 0.5s ease-out;
  -o-transition: opacity 0.5s ease-out;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
#ol-map {
  position: fixed; height: 100%; width:calc(100vw - 60px); top: 0px; right: 0px;
  background: #222327;
  /* transition: width 0.25s linear;
  -webkit-transition: width 0.25s linear;
  -moz-transition: width 0.25s linear;
  -ms-transition: width 0.25s linear;
  -o-transition: width 0.25s linear; */
}
#sidebar {
  width:462px;
  position: fixed;top: 0px;height: 100%;
  left: -402px;
  z-index: 1;
  background: #25292e;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 35%);
  /* transition: left 0.25s linear;
  -webkit-transition: left 0.25s linear;
  -moz-transition: left 0.25s linear;
  -ms-transition: left 0.25s linear;
  -o-transition: left 0.25s linear; */
}
/* .pin #ol-map, .pin #tool-info, .pin #popupOnMap, .pin #dashboardpanel {
  width: calc(100vw - 462px);
} */
#ol-map.pin  {
  width: calc(100vw - 462px);
}
.dList>div>div {
  display: table;
  padding: 8px;
}
.dList>div>div>div {
  display: table-cell;
  vertical-align: middle;
  color: #25292f
}
.dList>div>div>div:last-child {
  width: 60px;
}
.dList>div>div>div:nth-child(2) {
  width: 92px;
}

#sidebar.pin  {
  left: 0px;
}
#active-tool-title {
  color: #fff;
  text-transform: uppercase;
  height: 50px;
  display: table;
  width: 100%;
  background: #1e3642;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 17px;
}
.html-id #active-tool-title {
  font-size: 14px;
}
#active-tool-title span {
  display: table-cell;
  vertical-align: middle;
  padding: 10px 15px;
  position: relative;
}
#active-tool-title span:first-child::after {
  content: "BETA";
  margin-left: 10px;
  font-size: 10px;
  letter-spacing: 3px;
  border: 1px solid;
  padding: 3px 8px;
  padding-right: 5px;
  position: absolute;
  background: rgb(255 94 0 / 47%);
  color: rgb(255 255 255);
  top: 14px;
}
#pinned {
  width: 60px;
  text-align: center;
  margin-right: 15px;
  font-size: 11px;
  color: #b3b5b5;
  cursor: pointer;
}
.d-angle {
  border: 1px solid;
  width: 16px;
  height: 16px;
  text-align: left;
  border-radius: 100%;
  font-weight: bold;
  font-size: 12px;
}
.fa-angle-left.d-angle {
  padding-left: 3px;
}
#sb-menu{
  width: 60px;
  background: #25292e;
  position: absolute;
  height: 100%;
  right: 0;
  /* bottom: 0 */
  bottom: -50px;
  padding-bottom: 50px
}
#sb-menu-top h6 {
  color: #fff;
  text-align: center;
  font-family: 'Roboto Condensed';
  font-weight: bold;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
#sb-menu-inner {
  position: relative;
  height: 100%;
  text-align: center;
}
#sb-menu .sb-menu {
  padding: 14px 0px;
  cursor: pointer;
}
#sb-menu .sb-menu.sb-menu-tools {
  padding: 5px 13px;
  position: relative;
}
#sb-menu-bottom {
  position: absolute;
  width: 100%;
  bottom: 10px;
}
#menu-add-layer {
  text-align: center;
}
#menu-add-layer img {
  width: 44px;
}
#sb-menu .sb-menu h5 {
  color: #fff;
  font-size: 8px;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  margin-top: 9px;
  font-weight: 500;
  line-height: 1.1;
}
.html-id #sb-menu .sb-menu h5 {
  padding: 0px 5px;
}
#sb-bottom-menu {
  width: 402px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  padding-left: 10px;
  background: #25292e;
}
#sb-bottom-menu ul {
  margin-bottom: 5px;
  padding-top: 5px;
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
  margin-top: 0;
}
#sb-bottom-menu li {
  color: rgba(255,255,255,.8);
  padding: 8px;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
}
#tool-info,#popupOnMap,#dashboardpanel {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  text-align: left;
  width: 100%;
  height: 100%;
  word-break: break-word;
  background: rgba(0,0,0,0.9);
  color: #fff;
  overflow: auto;
  /* transition:all 0.25s linear;
  -webkit-transition:all 0.25s linear;
  -moz-transition:all 0.25s linear;
  -ms-transition:all 0.25s linear;
  -o-transition:all 0.25s linear; */
}
#dashboardpanel .popup-header {
  background: #1f3642;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 15px;
  height: 50px;
}
#dashboardpanel .popup-inner {
  background: transparent;
  overflow: unset;
}
.dash-row {
  background: linear-gradient(45deg, #24282e, #182e3a);
  margin-top: 15px;
}
.dash-row h2 {
  font-size: 1.8rem;
}
.dash-box {
  background: #fff;
  margin-bottom: 20px;
}
.dash-box >div {
  height: 264px;
  overflow: auto;
}
.dash-box h3 {
  background: #317597;
  padding: 12px 8px;
  font-size: 1.3rem;
  margin-bottom: 0;
  margin-top: 10px;
}
.dash-box table {
  color: #25292f
}
.dash-box table tbody td:first-child {
  padding-left: 8px;
}
.dash-box table tbody td:last-child {
  padding-right: 8px;
  width: 62px;
}
.dash-box table tr td:nth-child(2) {
  width: 90px;
}
.dash-box >div>p {
  color: #25292f;
  padding: 8px;
}
#dash-option > div {
  display: table-cell;
  vertical-align: top
}
#dash-region {
  width: auto;
  position: relative;
}
#dash-region ul {
  position: absolute;
  background: #fff;
  width: 100%;
  z-index: 9;
  color: #25292f;
  box-shadow: 0 0 10px rgb(0 0 0 / 30%);
}
#dash-region ul li {
  padding: 8px 20px;
  font-size: 1.5rem;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
}
#dash-region ul li.active, #dash-region ul li:hover {
  background: #4e9cc4;
  color: #fff;
  font-weight: 500;
}
#dash-region ul li.active:hover {
  background: #4e9cc4;
}
#dash-region ul li:hover {
  background: #7eb0ca;
}
#dash-region h5 {
  padding: 10px 20px;
  border-radius: 5px;
  height: 52px;
  margin: 0;
  display: table-cell;
  vertical-align: middle;
  border: 3px solid #4e9cc4;
  cursor: pointer;
  width: 242px
}
#dash-region h5 i.r-sort-down {
  border-top: 5px solid #fff;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent
}

#dash-date {
  padding-left: 20px;
}
#dash-date input {
  color: #25292f;
  text-align: center;
  letter-spacing: 1px;
}
#tool-info::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
}
#tool-info .table-cell {
  padding: 15px;
}
#tool-info > div > div > div {
  position: relative;
  z-index: 1;
  transition: opacity 0.25s ease-in-out,z-index 0.25s ease-in-out;
  -ms-transition: opacity 0.25s ease-in-out,z-index 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out,z-index 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out,z-index 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out,z-index 0.25s ease-in-out;
}
.go-child {
  background: rgba(52,120,153,.64);
  border: 2px solid #347899;
  font-weight: 600;
  min-width: 126px;
  text-align: left;
  border-radius: 17px;
  margin: 0 10px 0 0px;
  transition: all 0.25s ease-in-out;
  padding-bottom: 7px;
}
.go-child span:last-child {
  float: right;
  margin-top: -1px;
  display: inline-block;
  margin-left: 10px;
}
.go-child:hover {
  background: rgba(52,120,153,.8);
  border: 2px solid rgba(52,120,153,.5);
}
.tool-info-title {
  font-size: 52px;
  margin-bottom: 24px;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 0 0 15px #000;
  color: #fff;
}
.tool-info-desc {
  max-width: initial;
  margin: 0;
  margin-bottom: 30px;
  font-size: 22px;
  text-shadow: 0 0 15px #000;
  color: #fff;
}
.tool-info-option-text {
  font-size: 20px;
  margin-bottom: 20px;
  text-shadow: 0 0 15px #000;
  font-weight: 600;
}
.sum-d {
  margin-bottom: 30px;
  text-shadow: 0 0 15px #000;
  color: #fff;
}
.sum-t, .sum-g, .sum-g2 {
  display: table-cell;
  vertical-align: top;
  padding-right: 20px;
  padding-bottom: 5px;
  text-align: center;
  max-width: 220px;
}
.sum-g, .sum-g2 {
  padding-left: 20px;
  border-left: 2px solid #fff;
}
.start {
  text-align: center;
  margin: 0;
  background: #4e9cc4;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 10px 20px;
  width: 257px;
  border-radius: 18px;
  transition: background 0.25s ease-in-out;
}
.start:hover {
  background: #347899;
}
.sidebar-map-tool {
  padding: 10px 15px;
  float: left;
}
#sb-main-title, .sb-main-title {
  font-size: 13px;
  color: #8addff;
  margin: 0;
  margin-top: 3px;
}
#sb-map-tool-sub {
  font-weight: 400
}
#lang-switcher {
  border: 1px solid #7d7b7b;
  padding: 2px 10px;
  margin-top: 10px;
  display: inline-block;
  border-radius: 2px;
  margin-right: 15px;

}
#lang-switcher img {
  cursor: pointer;
  height: 14px;
  width: auto;
  opacity: 1;
  padding-bottom: 3px;
}
#lang-switcher b.active {
  position: relative;
}
#lang-switcher b.active img {
  opacity: 0.8;
  padding-bottom: 5px
}
#lang-switcher b.active::before {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  border-top: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #4e9cc4;
  border-left: 4px solid transparent;
  top: 11px;
  left: 3px;
}
#searchbox-block {
  margin: 15px;
  margin-top: -15px;
}
#atlasSbox-loader {
  width: 100%;
  height: 36px;
  background: #1a1c20;
  padding-left: 5px;
  margin-top: 20px;
  margin-bottom: 6px;
  border-radius: 12px;
  padding-top: 3px;
}
#atlasSbox-loader i {
  position: relative;
  display: inline-block;
  top: 1px;
  color: #80cdec;
  left: 3px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 1.1rem;
}
#the-basics {
  width: 100%;
  position: relative;
  height: 36px;
  background: #1a1c20;
  padding-left: 26px;
  margin-top: 20px;
  margin-bottom: 6px;
  border-radius: 12px;
}
#company-search-button {
  position: absolute;
  left: 0;
  top: 0;
  border: none;
  background: transparent;
  color: #8addff;
  width: 37px;
  height: 36px;
  z-index: 1;
  border-radius: 0;
  outline: none;
}
#advance-search-button {
  color: #ededed;
  text-decoration: none;
  font-weight: 600;
  border-radius: 2px;
  margin-top: 5px;
  display: inline-block;
  text-shadow: 0px 1px 5px rgb(0 0 0 / 80%);
  letter-spacing: 0.07em;
  float: right;
  padding-right: 8px;
  cursor: pointer;
}
#sb-content, #sb-tabs-block {
  width: 100%;
  color: #fff;
  padding-right: 60px
}
#sb-tabs-block {
  display: table;
  height: 100%;
  width: 100%;
}
#sb-tabs-block>div {
  display: table-cell;
  vertical-align: top;
  position: relative;
}
#sb-tabs {
  width: 52px;
  padding-top: 8px;
}
#atlasSbox {
  width: 100%;
  margin-right: 86px;
  height: 36px;
  border: none;
  padding: 10px;
  font-size: 13px;
  background: transparent;
  color: #a3a9a7;
}
#atlasSbox:focus {
  outline: none
}
.tt-menu {
  background: #fff;
  width: 100%;
  max-height: 300px;
  overflow: scroll;
  border: 1px solid #d4d4d4
}
.tt-suggestion, .get-group, .as-get-list-content, #popup-advanced-search .ui-selectmenu-button {
  padding: 5px 10px;
  cursor: pointer;
  color: #333;
}
.tt-suggestion:hover, .get-group:hover, .as-get-list-content:hover, .ui-selectmenu-button .ui-state-active, .tt-hover {
  background: #4e9cc4;
  font-weight: 600;
  color: #fff;
}
.sum-d > div > div {
  font-size: 52px;
}
#menu-show-sb {
  padding-bottom: 15px;
  border-bottom: 1px solid #6b6b6b;
  cursor: pointer;
  color: #dbffdb;
  margin: 0px 13px;
}
.nav-tabs {
  border-bottom: 0;
  margin-top: -10px;
}
.nav-tabs>li {
  float: none;
  color: #fff;
  padding: 10px 0;
  padding-left: 12px;
  padding-right: 5px;
  font-family: "Roboto Condensed";
  border: none !important;
  position: relative;
  z-index: 2;
  text-align: center;
  padding-top: 4px;
}
.nav-tabs img {
  width: 30px;
}
#advance-search-button {
  color: #ededed;
  text-decoration: none;
  font-weight: 600;
  border-radius: 2px;
  margin-top: 5px;
  display: inline-block;
  text-shadow: 0px 1px 5px rgb(0 0 0 / 80%);
  letter-spacing: 0.07em;
  float: right;
  padding-right: 8px;
}
.sb-title-pane {
  color: #fff;
}
.titlePane {
  background: #31769a;
  padding: 10px 15px;
  font-family: "Roboto Condensed";
  letter-spacing: 1px;
  font-weight: 600;
}
#deforestation-panel .titlePane {
  background: #614b95;
}
.subTitlePane {
  background: #3885ad;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 5px
}
#deforestation-panel .subTitlePane {
  background: #7657bb;
}
.subTitlePane > div {
  flex: 1 1 50%;
  padding: 5px 10px;
}
.subTitlePaneHead {
  font-family: "Roboto Condensed";
  letter-spacing: 1px;
  font-weight: 600;
  opacity: 0.5;
  text-transform: uppercase;
}
.sbbody {
  background: #fff;
  padding: 15px;
  color: #333;
  padding-bottom: 20px;
}
#glad-panel .c3-axis.c3-axis-x tspan {
  display: none;
}
#glad-panel .slick-slider {
  margin-bottom: 20px;
}
#sb-tabs-panel .tab-content > div {
  overflow: auto;
  overflow-x: hidden;
  margin-top: 30px;
  width: 350px;
  height: calc(100vh - 245px);
}
.tab-label {
  margin-top: 0;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 13px;
}
.s-track {
  background: #e4e4e4;
  height: 3px;
}
.s-track.s-track-1 {
  background: #4e9cc4;
}
.s-thumb {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #fff;
  box-shadow: 0 0 3px #949494;
  margin-top: -6px;
  border-color: #d5d5d5;
  cursor: pointer;
}
.s-thumb:focus {
  outline: none;
  box-shadow: 0 0 10px #8addff;
  border-color: #8addff;
}
.horizontal-slider {
  margin-top: 20px;
  margin-bottom: 40px;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 2.5rem;
  line-height: 2.5rem;
  font-size: 1.3rem;
  margin: 0.3rem
}
.react-datepicker__day--outside-month {
  opacity: 0.35;
}
.react-datepicker__header {
  background-color: #ffffff;
  border-bottom: 1px solid #f5f5f5;
}
.react-datepicker__current-month {
  font-size: 1.3rem;
  color: #797979;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-top: none;
  border-bottom-color: #ffffff;
}
.react-datepicker-popper {
  z-index: 4;
}
#layers-block > div {
  background: #353941;
  margin: 0 0 15px 0;
  padding: 10px 15px;
  cursor: move;
}
.layer>div {
  position: relative;
  padding-left: 5px;
  font-weight: 600;
}
.layer>div::before {
  content: "";
  width: 6px;
  height: 10px;
  background: url("https://nusantara-atlas.org/assets/images/icons/icon-move.png") top center;
  position: absolute;
  top: 5px;
  left: -8px;
  background-size: cover;
}
.layer table {
  width: 100%;
}
#layers-block .ui-slider.ui-widget.ui-widget-content {
  border: none;
  background: rgb(78 156 196 / 30%);
}
#layers-block .ui-slider-range.ui-corner-all.ui-widget-header {
  background: #4e9cc4;
}
#layers-block .ui-slider-handle.ui-corner-all.ui-state-default {
  border-radius: 100%;
  background: #fff;
  box-shadow: 0 0 3px #ccc;
  margin-top: -2px;
  border-color: #d5d5d5;
  cursor: pointer;
}
#layers-block .ui-widget {
  width: 100%;
  position: relative;
  height: 3px;
  margin-top: 15px;
  margin-bottom: 20px;
}
#layers-block .ui-slider-handle.ui-corner-all.ui-state-default:focus {
  box-shadow: 0 0 10px #8addff;
  border-color: #8addff;
  outline: none
}
.popup {
  /* background: rgba(0,0,0,.7); */
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  padding: 15px;
  top: 0;
  right: 0;
  overflow: auto !important;
  transition: opacity 0.25s;
  -webkit-ransition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -ms-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
}
.popup>div {
  display: table;
  height: 100%;
  margin: auto;
  max-width: 750px;
  width: 100%;
  padding: 15px;
  position: relative;
  z-index: 2
}
.popup>div>div {
  padding: 15px;
  display: table-cell;
  vertical-align: middle;
}
.popup>div.popup-bg {
  background: rgba(0,0,0,.7);
  position: absolute;
  max-width: 100%;
  padding: 0;
  top: 0;
  left: 0;
  z-index: 1
}
.popup-header {
  background: #317597;
  display: table;
  width: 100%
}
.popup-header > div{
  padding: 10px 15px;
  color: #fff;
  font-weight: bold;
  vertical-align: top;
  display: table-cell
}
.popup-header > div:last-child{
  text-align: right;
}
.popup-inner {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  padding: 15px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  max-height: calc(100vh - 129px);
}
#layer-list >div>div>div {
  margin-bottom: 12px
}
.checkbox {
  margin-top: 0;
  padding-left: 20px;
}
.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
}
.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  -moz-transition: border 0.15s ease-in-out,color 0.15s ease-in-out;
  -ms-transition: border 0.15s ease-in-out,color 0.15s ease-in-out;
  -webkit-transition: border 0.15s ease-in-out,color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out,color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out,color 0.15s ease-in-out;
}
.checkbox input[type="checkbox"] {
  opacity: 0;
}
.checkbox input[type="checkbox"]:checked+label {
  color: #2c90bf;
}
.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  -moz-transition: border 0.15s ease-in-out,color 0.15s ease-in-out;
  -ms-transition: border 0.15s ease-in-out,color 0.15s ease-in-out;
  -webkit-transition: border 0.15s ease-in-out,color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out,color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out,color 0.15s ease-in-out;
}
.checkbox input[type="checkbox"]:checked+label::before {
  background-color: #549fc4;
  border-color: #549fc4;
}
.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0px;
  top: -1px;
  margin-left: -20px;
  padding-left: 1px;
  font-size: 15px;
  color: #555;
  text-align: center;
}
.checkbox input[type="checkbox"]:checked+label::after {
  content: "✓";
  color: #fff;
}
.layer-block-popup-info {
  display: table;
  width: 100%
}
.layer-block-popup-info > div {
  display: table-cell;
  vertical-align: top
}
.layer-block-popup-info span, .layer-block-popup-info a {
  width: 20px;
  border-radius: 100%;
  background: #9b9d9c;
  color: #fff;
  display: inline-block;
  height: 20px;
  vertical-align: middle;
  text-align: center;
  margin-left: 5px;
  cursor: pointer;
}
.layer-block-popup-info span:hover, .layer-block-popup-info a:hover {
  background: #4e9cc4;
  transition: all 0.25s ease-in-out;
}
.layer-icons {
  width: 80px
}
.layer-icons b, .layer-icons a {
  width: 21px;
  border-radius: 100%;
  background: #6d6d6d;
  color: #3d4140;
  display: inline-block;
  height: 21px;
  padding-top: 1px;
  vertical-align: middle;
  text-align: center;
  margin-left: 5px;
  cursor: pointer;
}
.layer-info:hover {
  background: #4ec1e3
}
.layer-times:hover {
  background: #d06c6c
}
.layer-dl:hover {
  background: #72bbc3
}
#total-active-layer {
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  color: rgba(255,255,255,.55);
  width: 23px;
  height: 23px;
  padding-top: 1px;
  font-weight: 600;
  border: 2px solid;
}
#sb-tabs li {
  position: relative;
  cursor: pointer;
}
#sb-tabs li.active::after {
  content: "";
  color: #f1f1f1;
  background: #8addff;
  height: 100%;
  width: 6px;
  position: absolute;
  top: 0;
  left: 0px;
}
.ol-control {
  position: absolute;
  background-color: rgba(37,41,47,.8);
  border-radius: 4px;
  padding: 2px;
}
.ol-control button {
  display: block;
  margin: 1px;
  padding: 0;
  color: #fff;
  font-size: 1.14em;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  line-height: .4em;
  background-color: transparent;
  border: none;
  border-radius: 100%;
}
.ol-zoom .ol-zoom-out {
  margin-top: 96px;
}
.ol-zoomslider {
  background-color: transparent;
  bottom: 50px;
  right: 14px;
  height: 87px;
}
.ol-zoomslider::before {
  content: "";
  width: 2px;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 2px;
  left: 12px;
}
.ol-zoomslider button {
  position: relative;
  height: 16px;
  width: 16px;
  background: #8addff;
  left: 2px;
  box-shadow: 0 0 10px #000000;
}
.ol-zoom .ol-zoom-in:focus, .ol-zoom .ol-zoom-out:focus, .ol-zoomslider button:focus {
  outline: none;
}

.ol-attribution {
  right: 0px;
  bottom: -8px;
  position: fixed;
  padding: 0;
  background: rgb(255 255 255 / 58%);
  padding-right: 5px;
  font-size: 9px;
  padding-top: 3px;
}
.ol-attribution > button {
  display: none;
}
.ol-attribution > ul {
  list-style: none;
  padding-left: 5px;
}
.ol-zoom.ol-control {
  right: 10px;
  bottom: 20px;
}
.ol-rotate  {
  display: none;
}
.ol-overviewmap button {
  display: inline-block;
  background: rgb(138 221 255);
  color: #1a1c20;
  font-weight: bolder;
  border: 2px solid #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 24%);
}
.ol-overviewmap button:focus {
  outline: none;
}
.ol-overviewmap .ol-overviewmap-map {
  border: 2px solid #fff;
  height: 96px;
  margin: 0;
  width: 96px;
  background: #222327;
  border-radius: 4px;
  bottom: 11px;
  position: relative;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  margin-right:13px
}
.ol-unselectable, .ol-viewport {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.ol-selectable {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.ol-overviewmap-box {
  border: 1px solid rgb(255 255 255);
  background: rgba(255,255,255,0.1)
}
.ol-overviewmap.ol-unselectable.ol-control {
  right: 40px;
  left: auto;
  bottom: 20px
}
.ol-overviewmap:not(.ol-collapsed) button {
  bottom: 0;
  left: auto;
  position: absolute;
  right: 0;
}
.ol-overviewmap.ol-unselectable.ol-control.ol-collapsed .ol-overviewmap-map {
  display: none;
}
.ol-overviewmap.ol-unselectable.ol-control {
  padding: 0;
  background: none;
}
.popup.full-p>div {
  max-width: 1040px;
  width:100%;
  z-index: 999;
}
.popup.full-p>div.popup-bg {
  max-width: 100%;
}
#popupOnMap {
  background: #fff;
}
#popupOnMap .popup-inner {
  color: #515866;
  padding: 40px 30px;
  max-height: 100%
}
#layer-info-title {
  margin-top: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid;
  padding-bottom: 10px;
  font-family: "Roboto Condensed";
  text-transform: uppercase;
}
#layer-info-desc {
  font-size: 16px;
}
.info-desc-img {
  position: relative;
  margin-bottom: 30px;
}
.info-desc-img img {
  width: 100%;
  height: auto;
}
.info-desc-img-caption {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 15px 20px;
  color: #fff;
  font-size: 14px;
  text-shadow: 0 0 10px #000;
}
#layer-info-desc p {
  margin-bottom: 20px;
}
#legendID-SearchedLayer > div {
  width: 14px;
  height: 14px;
  border: 2px solid rgb(0, 232, 255);
}
#legendID-ClickedLayer > div {
  width: 14px;
  height: 14px;
  border: 2px solid rgb(255, 247, 0);
}
#glad-from,#glad-to {
  font-size: 12px;
  height: 23px;
  text-align: center;
  border: 1px solid #dedede;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 10%);
  max-width: 120px
}
#glad-checkbox {
  margin-top: 10px;
}
#glad-checkbox label {
  font-weight: 600;
  font-size: 1.4rem;
}
.res-value {
  font-size: 24px;
  color: #444;
}
.res-value-s {
  color: #333;
}
.res-ha {
  color: #876542;
  font-size: 14px;
  padding-left: 5px;
}
.stat-d > span {
  color: #307c9a;
  display: block;
  cursor: pointer;
}
.stat-di {
  padding-left: 15px
}
.stat-di > div {
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #f3f3f3
}
.stat-di > div:last-child {
  border-bottom: 0
}
.stat-di > div > div {
  display: block;
  font-family: 'Roboto Condensed';
  color: #c39800;
}
.stat-di.stat-di2 {
  margin-top: 10px;
}
.stat-di2 > div {
  border-bottom: 0
}
.rank-table {
  display: table;
  width: 100%;
  margin-bottom: 18px;
}
.rank-table > div {
  display: table-cell;
  vertical-align: top;
}
.rank-table > div:first-child {
  padding-right: 10px;
  width: 65%
}
.see-rank {
  color: #c39800;
  font-family: 'Roboto Condensed';
  font-size: 12px;
}
.see-rank small {
  display: block;
  color: #999;
  text-decoration: underline;
  font-family: 'Roboto Condensed';
  cursor: pointer;
}
.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.slick-dots li {
  width: auto;
}
.slick-dots li button {
  border-radius: 100%;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  padding: 3px;
  color: transparent;
  border: 2px solid #4e9cc4;
  outline: none;
  background: transparent;
  width: 5px;
  height: 5px;
}
.slick-dots li.slick-active button {
  background: #4e9cc4
}
.slick-dots li button:before {
  display: none
}
.slick-list div:focus {
  outline: none;
}
.sbbody-li {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom:15px
}
.sbbody-li:last-child {
  border-bottom: 0;
  margin-bottom: 0
}
.sbbody-title {
  font-family: 'Roboto Condensed';
  color: #888;
  font-size: 13px;
  margin-top: 0;
}
.b-map {
  margin: 1%;
  display: inline-block;
  vertical-align: top;
  width: 23%;
  margin-bottom: 2%;
  padding: 0;
  cursor: pointer;
  background: none;
  font-weight: 500;
  color: #888;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
}
.b-map img {
  width: 100%;
  margin-bottom: 5px;
  border: 2px solid #d8d8d8;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
}
.b-map:hover img, .b-map.active img {
  border: 2px solid #4e9cc4;
  box-shadow: 0 0 10px -5px #000;
}
.b-map:hover, .b-map.active  {
  color: #4e9cc4;
}
#cursorCord {
  position: absolute;
  top: 15px;
  left: 15px;
  background: rgb(0 0 0 / 50%) none repeat scroll 0 0;
  color: #ffffff;
  padding: 5px 10px;
  z-index: 31;
  border: 1px solid rgba(0,0,0,.3);
  font-weight: 600;
}
#scalebar {
  position: absolute;
  top: 56px;
  left: 16px;
}
.ol-scale-step-marker {
  width: 1px;
  height: 15px;
  background-color: #000000;
  float: right;
  z-Index: 10;
}
.ol-scale-singlebar {
  position: relative;
  height: 5px;
  z-Index: 9;
  box-sizing: border-box;
  border: 1px solid black;
}
.ol-scale-step-text {
  position: absolute;
  bottom: -5px;
  font-size: 10px;
  z-Index: 11;
  color: #ffffff;
  font-weight: 600;
  text-shadow: 0 0 5px BLACK;
}
#risk-slider {
  margin-bottom: 90px;
  margin-top: 55px;
}
#risk-slider .s-thumb {
  text-align: center;
}
#risk-slider .s-thumb b {
  position: relative;
  top: -22px;
  display: block;
  text-align: center;
  color: #3885ad;
  font-size: 1.5rem;
  left: -7px;
  right: auto;
  width: 30px;
}
#swipe73, #swipeAvs {
  width: 6px;
  height: 100vh;
  display: block;
  border-left: 1px solid #3f3063;
  border-right: 1px solid #3f3063;
  background-color: #614b95;
  z-index: 999;
  position: absolute !important;
  left: 50%;
  cursor: col-resize;
}
#swipeAvs {
  background-color: #c00000;
  border-left: 1px solid #900000;
  border-right: 1px solid #900000;
}
#swipe73:before, #swipe73:after {
  font-size: 18px;
  background: rgba(118,87,187,.77);
  padding: 5px 10px;
  color: #fff;
  position: absolute;
  margin-top: 60px;
}
#swipe73:before {
  content: "1973";
  margin-left: -66px;
  border-radius: 4px 0 0 4px;
}
#swipe73:after {
  content: "2020";
  margin-left: 10px;
  border-radius: 0 4px 4px 0;
}
#swipe73 .handle, #swipeAvs .handle {
  width: 24px;
  height: 32px;
  margin: -16px 0 0 -12px;
  position: absolute;
  z-index: 30;
  top: 52vh;
  left: 50%;
  background-color: #7657bb;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #3f3063;
}
#swipeAvs .handle {
  background-color: #c00000;
  border-color: #900000
}
#timeSliderBlock {
  position: absolute;
  bottom: 15px;
  z-index: 9;
  background: rgba(0,0,0,.7);
  left: 0;
  right: 0;
  margin: auto;
  max-width: 700px;
  display: table-cell;
  vertical-align: middle;
  line-height: 40px;
  border-radius: 3px;
  height: 49px;
}
#timeSliderBlock .table-cell {
  padding: 0px 24px;
  color: #fff;
  font-weight: 600;
}
#timeSliderBlock .table-cell:first-child {
  background: rgba(0, 0, 0, 0.41);
  text-align: center;
  padding-top: 0px;
  border-radius: 3px 0 0 3px;
  cursor: pointer;
  padding-left: 3px;
  padding-right: 3px;
}
#timeSliderBlock .table-cell:last-child {
  padding: 0;
}
#timer-button span {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  font-size: 11px;
  letter-spacing: 1px;
}
#timeSliderDiv {
  height: 4px;
  width: 99.8%;
}
#timeSliderDiv .ui-slider-handle.ui-corner-all.ui-state-default {
  width: 14px;
  height: 14px;
  margin-top: -2px;
  box-shadow: none;
  border-radius: 100%;
  cursor: pointer;
  background: #fff;
}
#timeslidertext {
  display: table;
  width: calc(100% + 100%/17);
  margin-top: -12px;
  margin-bottom: -5px;
  margin-left: calc(-100%/31);
  margin-right: calc(-100%/31);
}
#timeslidertext > div {
  display: table-cell;
  color: #fff;
  width: calc(100%/19);
  text-align: center;
  font-size: 9px;
  position: relative;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
}
#switch-tl {
  margin-right: 2px;
  cursor: pointer;
}
#switch-tl img {
  height: 41px;
  width: auto;
  padding: 1px;
  background: #fff;
}
.tl-bg {
  padding-bottom: 40px;
  background: #353941;
}
#sb-pie {
  margin-top: -5px;
  padding: 0 16px;
  padding-right: 0
}
#sb-pie .c3-legend-item text {
  fill: #fff;
}
.c3-tooltip {
  color: #2e3332;
}
.sb-chart-title {
  font-size: 13px;
  font-weight: 700;
  padding: 0px 20px;
  padding-top: 15px;
  display: table;
  width: 100%;
}
.sb-chart-title b {
  display: table-cell;
  vertical-align: top;
  width: 26px;
  text-align: right;
  background: none;
  border: 0;
  padding: 0;
}
.sb-chart-title b:first-child {
  width: auto;
  text-align: left;
}
#sb-slide-chart {
  margin-top: -20px
}
#sb-slide-chart .c3-legend-item text {
  fill: #fff;
}
#sb-slide-chart .c3-axis-x text {
  fill: #fff;
  font-size: 8px;
}
#sb-slide-chart .c3-axis.c3-axis-x > g:nth-child(even) text {
  display: none !important;
}
#sb-slide-chart .c3-chart-bars .c3-shape.c3-bar {
  /* opacity: 0.5 !important */
}
#sb-slide-chart .slick-next, #sb-slide-chart .slick-prev{
  color: #fff;
}
.slick-next, .slick-prev {
  right: -8px;
  height: auto;
  width: 34px;
  z-index: 1;
  top: 47.6%;
}
.slick-prev {
  left: -9px;
}

#popup-search.popup, #popup-search.popup.popup-min,
#popup-search.popup .popup-inner, #popup-search.popup.popup-min .popup-inner {
  transition-property: width, height, top, right;
  transition-duration: 0.25s;
  transition-timing-function: linear;
  /* -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear */

}
#popup-search .popup-inner {
  font-family: 'Roboto Condensed';
}
#popup-search .popup-header > div:first-child{
  cursor: pointer;
}
#popup-search .popup-header > div:last-child span {
  margin-left: 10px;
  cursor: pointer;
}
#popup-search.popup>div {
  max-width: 860px
}
#popup-search.popup>div.popup-bg {
  max-width: 100%;
  /* transition: none !important; */
}
.popup.popup-min {
  width: 400px;
  right: 15px;
  height: 38px;
  top: 15px;
  padding: 0;
  overflow: hidden;
}
.popup.popup-min > div {
  padding: 0;
}
.popup.popup-min >div>div {
  padding: 0;
}
.popup.popup-min .popup-inner {
  height: 0;
  padding: 0;
}
.total-search-result {
  color: #999;
  padding-right: 10px;
  border-right: 1px solid;
}
.total-search-result-area {
    color: #999;
    padding-left: 10px;
}
#table-basic-result th, .table-ranklist th {
  cursor: pointer;
  color: #888;
  text-transform: uppercase;
  padding: 8px 4px;
}
#table-basic-result th.active {
  color: #333;
  background-color: transparent;
}
#table-basic-result td, .table-ranklist td {
  padding: 8px 4px;
}
#table-basic-result, .table-ranklist {
  font-size: 12px;
}
#table-basic-result th:first-child, .table-ranklist th:first-child, #table-basic-result td:first-child, .table-ranklist td:first-child {
  padding-left: 0
}
#table-basic-result th:last-child, .table-ranklist th:last-child, #table-basic-result td:last-child, .table-ranklist td:last-child {
  padding-right: 0
}
#table-basic-result th > div > div {
  position: relative;
}
#table-basic-result th > div > div > svg {
  position: absolute;
  top: 0;
}
#table-basic-result th > div > div > svg:last-child {
  bottom: 0;
  top: auto;
}
.search-viewmap, .search-showmap, .search-showstat {
  background: #3e92bc;
  border-radius: 20px;
  color: #fff;
  padding: 3px 10px 1px 10px;
  font-family: 'Roboto Condensed';
  letter-spacing: 1px;
  font-size: 10px;
  cursor: pointer;
  font-weight: 600;
}
.sort-by-label {
  font-size: 13px;
  color: #888;
  font-weight: 600;
  margin-bottom: -10px;
}
.sort-block {
  margin-top: 10px;
  margin-bottom: 5px;
}
.sort-block > li {
  vertical-align: middle;
}
.sort-select {
  position: relative;
  background: #ececec;
  min-width: 130px;
}
.sort-select h5 {
  padding: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  color: #888;
  margin: 5px;
}
.sort-select ul {
  position: absolute;
  top: 32px;
  background: #ececec;
  width: 100%;
  z-index: 1;
  box-shadow: 0px 4px 10px -3px rgb(0 0 0 / 40%);
  height: auto;
  max-height: 216px;
  overflow: auto;
  margin-top: -8px;
}
.sort-select li {
  border-top: 1px solid #ddd;
  font-weight: 600;
  color: #666;
  width: 100%;
  display: table;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
}
.sort-select li:hover {
  background: #3e92bc;
  color: #fff
}
.sort-select li span {
  display: table-cell;
  vertical-align: middle;
  padding: 5px 10px;
}
#refilter {
  color: #e53845;
  cursor: pointer;
  font-size: 12px;
  margin: 0;
  font-weight: 600
}
#refilter svg {
  top: -2px;
  position: relative;
}
#refilter path {
  stroke-width: 60px !important;
}
.ap-switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 18px;
  margin-bottom: 0;
}
.ap-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.ap-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 17px;
  background-color: #64bd45;
  -webkit-transition: .4s;
  transition: .4s;
}
.ap-slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
.ap-switch input:checked + .ap-slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
i.r-sort-down {
  border-top: 4px solid #888;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  top: 5px;
  float: right;
  position: relative;
  width: 0;
  height: 0;
  display: inline-block;
  margin-left: 10px;
}
.table-advanced-search > tbody > tr > th {
  color: #4d4848;
  width: 265px;
  font-weight: 600;
  font-family: "Roboto";
  height: 34px;
  padding: 0!important;
  padding-top: 18px!important;
  border-top: 0!important;
  border-bottom: 1px solid #ddd
}
.table-advanced-search > tbody > tr > td {
  border-top: 0!important;
  border-bottom: 1px solid #ddd;
}
.table-advanced-search .horizontal-slider {
  margin-top: 10px
}
.checkbox-search {
  margin-bottom: 0;
}
.checkbox-inactive label, .checkbox-inactive label:hover {
  color: #aaa !important;
  cursor: not-allowed !important;
}
#as-cancel, #as-filter, #as-filter-switch {
  border: 2px solid #767676;
  color: #767676;
  border-radius: 2px;
  font-family: "Roboto";
  width: 124px;
  font-size: 11px;
  height: 36px;
  padding-top: 9px;
  font-weight: bold;
  background: #fff
}
#as-filter {
  border-color: #469ae3;
  background: #469ae3;
  color: #fff;
  margin-right: 10px
}
#as-filter-switch img {
  height: 18px;
  margin-right: 5px;
  width: auto;
  margin-top: -4px;
}
.arange-slider {
  margin-top: -15px;
  color: #8f8f8f;
  display: table;
  width:100%
}
.arange-slider>div{
  display: table-cell;
  vertical-align: top
}
.about-title {
  font-size: 20px;
  font-family: Roboto Condensed;
  margin-top: 0;
  letter-spacing: 1px;
}
.feedback-con {
  background: #4483a3;
  color: #fff;
  margin: -25px -15px 30px -15px;
  padding: 15px;
}
#feedback-button {
  background: #4483a3;
}
#aversat, #aversat2 {
  position: absolute;
  right: 70px;
  bottom: 20px;
  z-index: 99;
  border: 2px solid darkred;
  border-radius: 100%;
  padding: 10px;
  cursor: pointer;
  background: rgba(192,0,0,.55);
  color: #fff;
}
#aversat2 {
  right: auto;
  left: 15px
}
#aversat.a, #aversat2.a {
  background: #c00000;
}
#aversatpop, #aversatpop2 {
  position: absolute;
  bottom: 50px;
  right: 100px;
  background: #fff;
  z-index: 9;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  max-width: 256px;
  width: 100%;
}
#aversatpop2 {
  right: auto;
  left: 46px
}
.avhead {
  background: #900000;
  color: #fff;
  margin-bottom: 0;
  padding: 0px 15px;
  width: 100%;
  display: table;
  cursor: pointer;
}
#aversatpop h5, #aversatpop2 h5 {
  font-weight: 600;
}
.avbody {
  padding: 15px;
  padding-top: 0;
}
#aversatcal .react-datepicker,#aversatcal .react-datepicker__month-container {
  width: 100%
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background: #3885ad;
  border-radius: 100%;
  font-weight: 600;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  border-radius: 100%;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  border-radius: 100%;
}
tr.hl-this {
  background: #ffedb1;
  color: #755e0d!important;
  letter-spacing: 1px;
  font-weight: 700;
  text-shadow: 1px 1px 0 #fff8e1;
}
.pagination span {
  cursor: pointer;
  color: #ffffff;
  font-weight: 500;
  padding: 5px 10px 4px 10px;
  letter-spacing: 1px;
  background: #3e92bc;
  text-transform: uppercase;
  font-size: 11px;
}
#welcome-block {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;right: 0;
  color: #fff;
  z-index: 99
}
#welcome-screen > div {
  display: table;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.25s linear
}
#welcome-screen > div > div {
  display: table-cell;
  vertical-align: middle;
  padding: 30px;
}
.welcome-screen-title {
  width: 100%;
  bottom: 0;
  color: #fff;
  font-weight: bold;
  background: rgba(0,0,0,0.3);
  background: -webkit-linear-gradient(transparent,rgba(0,0,0,0.9));
  background: -moz-linear-gradient(transparent,rgba(0,0,0,0.9));
  background: -ms-linear-gradient(transparent,rgba(0,0,0,0.9));
  background: -o-linear-gradient(transparent,rgba(0,0,0,0.9));
  background: linear-gradient(transparent,rgba(0,0,0,0.9));
  text-shadow: 0 0 10px #000;
  letter-spacing: 1px;
  position: relative;
  background: none;
  padding: 0;
  font-size: 52px;
  font-family: "Roboto";
  margin-bottom: 20px;
}
.welcome-screen-blurb {
  font-size: 24px;
  text-shadow: 0 0 15px #000;
  margin-top: 20px;
}
#welcome-dot {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 30px;
  text-align: center;
  color: #fff;
}
#welcome-dot span {
  cursor: pointer;
  border: 3px solid #fff;
  border-radius: 100%;
  width: 17px;
  height: 17px;
  display: inline-block;
  margin: 0 6px
}
#welcome-dot span.a {
  background: #fff
}
.udraw {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: linear-gradient(45deg, #a7c1d0, #fffcfc);
  z-index: 1;
  padding: 20px;
  width: 29%;
}
.udraw-text {
  font-size: 16px;
}
#udraw-text-toggle {
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  background: rgb(91 169 202);
}
#draw-end, #uzip-email {
  margin-top: 20px;
}
#udraw-submit, #redraw, #uzip-submit, #uzip-btn {
  display: inline-block;
  vertical-align: middle;
  margin-top: 20px;
}
#uzip-submit, #udraw-submit {
  margin-right: 20px;
}
#udraw-error, #uzip-error {
  margin-top: 20px;
  margin-bottom: 0px;
}
#draw-end input {
  box-shadow: inset 0 0 12px rgb(0 0 0 / 20%);
  border-color: #5ba9ca;
}
label.file-upload input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: 0;
  background: #fff;
  cursor: inherit;
  display: block;
}
.tab-info {
  cursor: pointer;
  float: right;
  background: none;
  border: 0;
  padding: 0;
}
.tl-bg .tab-info {
  float: none;
}
.share-button {
  margin-top: 25px;
}
.share-button li {
  margin-bottom: 36px;
  vertical-align: top
}
.share-button a {
  display: block;
  position: relative;
  width: 86px;
  height: 86px;
}
.share-button a:hover {
  color: inherit;
  opacity: .9;
}
.share-button i {
  display: block;
  width: 86px;
  height: 80px;
  outline: none;
  text-align: center;
  padding-top: 15px;
  margin-bottom: 9px;
  border-radius: 3px;
  transition: all 0.25s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}
.share-button a:hover i {
  margin-top: 3px;
  top: -10px;
}
.share-button svg {
  color: #fff
}
.share-button div {
  padding-top: 86px;
}
.share-button .facebook i {
  background: #3B5998;
}
.share-button .email i {
  background: #444;
}
.share-button .linkedin i {
  background: #4875B4;
}
.share-button .twitter i {
  background: #55acee;
}
