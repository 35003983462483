* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.inline-block {
  display: inline-block;
}
.display-table {
  display: table;
}
.table-cell {
  display: table-cell;
}
.vertical-middle {
  vertical-align: middle;
}
.vertical-top {
  vertical-align: top;
}
.width-65 {
  width: 65%
}
.width-50 {
  width: 50%
}
.width-100 {
  width: 100%
}
.width-15px {
  width: 15px
}
.width-70px {
  width: 70px;
}
.height-100 {
  height: 100%
}
.margin-top-15px {
  margin-top: 15px
}
.padding-0 {
  padding: 0
}
.padding-right-0 {
  padding-right: 0
}
.padding-left-0 {
  padding-left: 0
}
.padding-left-15px{
  padding-left: 15px
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default !important;
}
.border-radius-3px {
  border-radius: 3px;
}
.color-white {
  color: #fff
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  padding: 0;
  height: 100%;
  width: 100%;
  font-size: 13px;
  color: #333;
}
button {
  border: 0;
}
.btn-primary {
  color: #fff;
  background-color: #5ba9ca;
  border-color: transparent;
  font-family: 'Roboto Condensed';
}
.btn-default {
  font-family: 'Roboto Condensed';
}
