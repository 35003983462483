@media (max-width: 768px){
  .nomobile {
    display: none;
  }
  #active-tool-title {
    font-size: 12px;
  }
  #sidebar {
    width: 100vw;
    left: -100vw;
    /* left: -webkit-calc( - (100vw - 80px));
    left: -moz-calc( - (100vw - 80px));
    left: -ms-calc( - (100vw - 80px));
    left: -o-calc( - (100vw - 80px)); */
  }
  #sb-menu {
    right: -60px;
    z-index: 1;
    top: 0;
    padding-bottom: 0 !important;
    bottom: 0 !important;
  }
  #sb-tabs-panel .tab-content>div {
    width: 100%;
    height: calc(100vh - 295px);
  }
  #tool-info, #popupOnMap {
    width: calc(100vw - 60px) !important
  }
  #tool-info .table-cell {
    padding: 0;
  }
  .tool-info-title {
    font-size: 20px;
  }
  .tool-info-desc {
    font-size: 16px;
  }
  .sum-d > div > div {
    font-size: 20px;
  }
  .go-child {
    margin-top: 10px;
    font-size: 12px;
  }
  .start {
    font-size: 12px;
    display: block;
    width: 212px;
    padding: 10px 0;
  }
  #sidebar .tab-content {
    width: 100vw;
    padding: 0 10px;
    padding-bottom: 55px;
    margin-top: 50px;
  }
  #sb-content, #sb-tabs-block {
    padding-right: 0
  }
  #sb-tabs-block>div {
    display: block;
  }
  .tab-label {
    left: 10px;
    top: -30px
  }
  #total-active-layer {
    right: 10px;
    top: -35px
  }
  #sb-tabs {
    width: 100%;
    margin: 0 10px;
  }
  #sb-tabs li {
    width: 46px;
    display: inline-block;
    vertical-align: top;
    height: 45px;
    padding: 0 10px;
  }
  #sb-tabs li.active::after {
    top: auto;
    bottom: -5px;
    width: 100%;
    left: 0;
    height: 6px;
  }
  #sb-bottom-menu {
    width: 100vw;
    z-index: 5;
  }
  #sb-bottom-menu li {
    font-size: 11px
  }
  #pinned {
    display: none !important
  }
}
@media (min-width: 769px){
  .m-only {
    display: none!important
  }
}
@media (max-height: 800px) and (min-width: 769px){
  .tool-info-title, .sum-d > div > div {
    font-size: 34px;
  }
  .sum-d span {
    font-size: 15px;
  }
  .tool-info-desc {
    font-size: 18px;
  }
}

@media (max-height: 621px){
  .welcome-screen-title {
    font-size: 40px;
    line-height: normal;
  }
  .welcome-screen-blurb {
    font-size: 16px
  }
  #sb-menu #sb-menu-bottom .sb-menu {
    padding: 10px 0
  }
  #sb-menu-top svg, .sb-menu-tools img, #sb-menu-bottom img, #sb-menu-bottom svg {
    width: 26px;
  }
  #menu-add-layer img {
    width: 34px;
  }
  #menu-base-map img {
    width: 40px
  }
  #menu-show-sb svg {
    width: 20px;
  }
  #sb-menu-top h6 {
    font-size: 12px
  }
}
